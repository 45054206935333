/* GetForm.css */
.styled-table {
  border-collapse: collapse;
  width: 100%;
  margin: 25px 0;
  font-size: 18px;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px;
}

.styled-table th {
  background-color: #f4f4f4;
  color: #333;
}

.styled-table tr {
  border-bottom: 1px solid #ddd;
}

.styled-table tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.styled-table tr:hover {
  background-color: #f1f1f1;
}

.styled-table td {
  color: #555;
}
